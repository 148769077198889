import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import "../pages/mystyles.scss"


// From https://dev.to/eclecticcoding/bulma-navbar-toogle-with-react-hooks-18ek
export default function Navbar() {
    const [isActive, setisActive] = React.useState(false)
    return (
        <nav className="navbar has-background-dark pb-5" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link to="/" className="navbar-item has-text-primary pl-5">
                    <StaticImage 
                        src="../images/TopTalentLogo-green.png" 
                        alt="Top Talent pyramid logo" 
                        width={500}
                        height={500}
                    />
                </Link>
                
                <a
                    onClick={() => {
                        setisActive(!isActive)
                    }}
                    role='button'
                    className={`navbar-burger burger has-text-primary ${isActive ? 'is-active' : ''}`}
                    aria-label='menu'
                    aria-expanded='false'
                    data-target='navbarBasicExample'
                    >
                    <span aria-hidden='true'></span>
                    <span aria-hidden='true'></span>
                    <span aria-hidden='true'></span>
                </a>
            </div>
            <div id='navbarBasicExample' className={`navbar-menu has-background-dark  ${isActive ? 'is-active' : ''}`}>
                <div className='navbar-end'>
                <div className='navbar-item'>
                    <a href='/' className='navbar-item has-text-primary'>
                    Home
                    </a>
                    <div href='/' className='navbar-item has-dropdown is-hoverable' >
                        <a href="" className="navbar-item has-text-primary has-background-dark">About</a>
                        <div className="navbar-dropdown has-background-dark">
                            <Link to="/toptalent" className="navbar-item has-text-primary">Top Talent</Link>
                            <Link to="/judy-mchugh" className="navbar-item has-text-primary">Judy McHugh</Link>
                        </div>
                    </div>
                    <div href='/' className='navbar-item has-dropdown is-hoverable '>
                        <a href="" className="navbar-item has-text-primary has-background-dark">Services</a>
                        <div className="navbar-dropdown has-background-dark">
                            <Link to="/coaching" className="navbar-item has-text-primary">Coaching</Link>
                            <Link to="/leadership-development" className="navbar-item has-text-primary">Leadership Development</Link>
                            <Link to="/talent-performance-management" className="navbar-item has-text-primary">Talent & Performance Management</Link>
                        </div>
                    </div>
                    <Link to="/resources" className="navbar-item has-text-primary pr-5">Resources</Link>
                    <Link to="/contact" className="navbar-item button has-text-white has-background-warning">Contact</Link>
                </div>
                </div>
            </div>
        </nav>
    )
}