import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faImages,
    faUsers,
    faComments,
    faUserFriends,
    faLightbulb,
    faUserShield,
    faClipboardList,
    faChalkboardTeacher,
    faExpandArrowsAlt,
    faBullhorn,
    faUserCheck,
    faFileInvoiceDollar,
    faHome
} from '@fortawesome/free-solid-svg-icons'

import {
   faLeanpub, 
   faLinkedinIn
} from '@fortawesome/free-brands-svg-icons'

library.add(faImages)
library.add(faUsers)
library.add(faComments)
library.add(faUserFriends)
library.add(faLightbulb)
library.add(faUserShield)
library.add(faClipboardList)
library.add(faLeanpub)
library.add(faChalkboardTeacher)
library.add(faExpandArrowsAlt)
library.add(faBullhorn)
library.add(faUserCheck)
library.add(faFileInvoiceDollar)
library.add(faHome)
library.add(faLinkedinIn)