import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import "../pages/mystyles.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/font-awesome'

export default function Footer() {
    return (
        <footer className="has-background-dark">
            <div className="container pt-5">
                <div className="has-text-centered pb-5 pt-5">
                    <Link to="/">
                        <FontAwesomeIcon icon={'home'} size="2x" alt="home symbol"/>
                    </Link>
                </div>
                <div className="columns is-narrow">
                    <div className="column has-text-centered">
                        <StaticImage 
                            src="../images/TopTalentLogo-green.png" 
                            alt="Top Talent and pyramid logo" 
                            width={100}
                        />
                        <p className="has-text-primary is-size-6">© Copyright {new Date().getFullYear()} Top Talent, LLC</p>
                        <p className="has-text-primary is-size-6">All rights reserved.</p>
                    </div>
                    <div className="column">
                        <h3 className="has-text-light title is-4">Services</h3>
                        <ul>
                            <li><Link to="/coaching"  className="has-text-primary">Coaching</Link></li>
                            <li><Link to="/leadership-development" className="has-text-primary">Leadership Development</Link></li>
                            <li><Link to="/talent-performance-management" className="has-text-primary">Talent & Performance Management</Link></li>
                        </ul>
                    </div>
                    <div className="column">
                        <h3 className="has-text-light title is-4">About</h3>
                        <ul>
                            <li><Link to="/toptalent" className="has-text-primary">Top Talent</Link></li>
                            <li><Link to="/judy-mchugh" className="has-text-primary">Judy McHugh</Link></li>
                            <li><Link to="/resources" className="has-text-primary">Resources</Link></li>
                        </ul>
                    </div>
                    <div className="column is-narrow has-text-centered">
                        <h3 className="has-text-light title is-4">Connect</h3>
                        <a href="https://www.linkedin.com/in/judybmchugh/" className="icon has-text-primary"><FontAwesomeIcon icon={['fab', 'linkedin-in']} alt="linkedIn icon"/></a>
                    </div>
                </div>
                <div>
                    <p className="has-text-primary has-text-centered is-size-7 pt-5">Top Talent website designed by Jen @ <a href="https://www.helonaki.com">The Helonaki</a></p>
                </div>
            </div>
        </footer>
    )
}
